<template>
  <div class="container">
    <!-- <kdx-header-bar>
      <Form ref="form" :model="searchData" :label-width="100" inline @submit.native.prevent>
        <FormItem label="操作时间：">
          <DatePicker class="width-340" placeholder="请选择操作时间" type="datetimerange" format="yyyy-MM-dd HH:mm"
            :confirm="true" v-model="selectDate"></DatePicker>
        </FormItem>
        <div class="ivu-form-item-btn">
          <Button type="primary" @click="handleSearch">搜索</Button>
          <Button type="text" @click="handleReset">重置</Button>
          <Button type="text" @click="handleExport">导出</Button>
        </div>
      </Form>
    </kdx-header-bar> -->
    <div class="list-wrap" v-loading="loading">
      <Table ref="table" :columns="columns" :data="list"></Table>
      <div class="footer-page">
        <kdx-page-component ref="page" :total="total" @on-change="changePage"></kdx-page-component>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { formatDate } from '@/assets/helpers/index';
// import { config as settings } from '@/api/config/settings.js'
import { Export } from '@/helpers/export';
export default {
  inject: ['returnToTop'],
  components: {},
  props: {},
  data() {
    return {
      total: 0,
      page: 1,
      pagesize: 10,
      list: [],
      selectDate: ['', ''],
      // 搜索信息
      searchData: {
        startTime: '',
        endTime: '',
        level_id: 'all',
        keyword: '',
        export: '',
      },
      loading: false,
      member_id: '', //缓存路由的会员id
      columns: [
        {
          title: '余额变化',
          key: 'num',
          minWidth: 80,
          render: (h, params) => {
            const obj = params.row.typeName == '增加' ? { type: 'success', text: '+' } : { type: 'danger', text: '-' };
            return (
              <kdx-status-text type={obj.type} class="num-change">
                {obj.text}￥{params.row.changeMoney}
              </kdx-status-text>
            );
          },
        },
        {
          title: '当前余额',
          key: 'afterMoney',
          minWidth: 80,
          render: (h, params) => {
            return <div>￥{params.row[params.column.key]}</div>;
          },
        },
        {
          title: '备注',
          key: 'remarks',
          minWidth: 150,
        },
        {
          title: '操作时间',
          key: 'date',
          minWidth: 100,
          maxWidth: 180,
          render: (h, params) => {
            return <div>{formatDate(new Date(params.row[params.column.key] * 1000), 'yyyy-MM-dd hh:mm:ss')}</div>;
          },
        },
      ],
    };
  },
  watch: {
    // selectDate: {
    //   handler(value) {
    //     this.searchData.startTime =
    //       value[0] === ''
    //         ? ''
    //         : formatDate(new Date(value[0]), 'yyyy-MM-dd hh:mm:ss');
    //     this.searchData.endTime =
    //       value[1] === ''
    //         ? ''
    //         : formatDate(new Date(value[1]), 'yyyy-MM-dd hh:mm:ss');
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
  created() {
    this.getBalanceList();
  },
  methods: {

    // 余额列表
    async getBalanceList() {
      this.returnToTop();
      // let _params = {
      //   level_id:
      //     this.searchData.level_id === 'all'
      //       ? ''
      //       : this.searchData.level_id,
      //   keyword: this.searchData.keyword,
      //   export: this.searchData.export,
      //   page: this.page,
      //   pagesize: this.pagesize,
      //   member_id: this.member_id,
      // };
      // if (this.searchData.startTime) {
      //   _params['created_at[0]'] = this.searchData.startTime;
      // }
      // if (this.searchData.endTime) {
      //   _params['created_at[1]'] = this.searchData.endTime;
      // }
      this.loading = true;

      const result = await this.$api.finance.finlist({ page: this.page, size: this.pagesize });
      this.list = result.list;
      this.total = result.count;
      this.loading = false;
    },
    // 点击搜索按钮
    handleSearch() {
      this.page = 1;
      this.pagesize = 10;
      this.$refs['page'].reset();
      this.getBalanceList();
    },
    // 点击重置按钮
    handleReset() {
      this.selectDate = ['', ''];
      this.searchData.startTime = '';
      this.searchData.endTime = '';
      this.searchData.level_id = 'all';
      this.searchData.keyword = '';
      this.searchData.export = '';
      this.page = 1;
      this.pagesize = 10;
      this.$refs['page'].reset();
      this.getBalanceList();
    },

    // 切换页码
    changePage(page) {
      this.page = page.pageNumber;
      this.pagesize = page.pageSize;
      this.getBalanceList();
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background-color: #f4f6f8;
  margin: 20px auto;
}

.list-wrap {
  background: #fff;
  padding: 0 20px;
  position: relative;

  .nickname {
    display: flex;
    align-items: center;

    .avatar {
      padding-right: 10px;

      img {
        width: 46px;
        height: 46px;
        border-radius: 50%;
      }
    }

    .right {
      padding-left: 10px;

      .name {
        color: $brand-color;
        cursor: pointer;
      }

      .icon {
        margin-top: 4px;

        .iconfont {
          font-size: 20px;

          &.icon-H {
            color: #ff6004;
          }

          &.icon-weixin {
            color: #1bb723;
          }

          &.icon-weixinxiaochengxu {
            color: #677de0;
          }

          &.icon-zhifubaoxiaochengxu {
            color: #2094e7;
          }

          &.icon-douyin {
            color: #1f0a1c;
          }
        }
      }
    }
  }

  /deep/ .ivu-table {
    // 会员等级
    .vip-grade {
      display: flex;
      flex-wrap: nowrap;

      .icon {
        flex-shrink: 0;

        .svg-icon {
          font-size: 20px;
          padding-right: 4px;
          vertical-align: bottom;
        }
      }

      .text {
        width: 0;
        flex: 1;
      }
    }

    // 余额变化
    .num-change {
      .status-text-size-default {
        font-weight: bold;
      }
    }
  }
}

.ivu-form .ivu-form-item {
  margin-bottom: 0;

  &:nth-child(4) {
    margin-right: 20px;
  }
}
</style>
